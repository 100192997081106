<template>
  <div>
    <sb-loading v-if="loading" />
    <sb-page-header>
      <template v-slot:left>
        <h1>
          Schooldistrictbeheerder {{ editMode ? 'aanpassen' : 'toevoegen' }}
        </h1>
      </template>
    </sb-page-header>

    <sb-user-form
      :default-data="data"
      :mode="data ? 'edit' : 'create'"
      :role="Role.MANAGER"
      :organisation-id="organisationId"
    />
  </div>
</template>

<script>
import SbLoading from '@/components/SbLoading';
import SbPageHeader from '@/components/SbPageHeader';
import { getResourceId } from '@/lib/user-context-manager';
import SbUserForm from '@/components/SbUserForm/SbUserForm.vue';

export default {
  components: {
    SbPageHeader,
    SbLoading,
    SbUserForm,
  },

  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  computed: {
    editMode() {
      return this.data;
    },
    organisationId() {
      return this.$route.query.organisationId || getResourceId();
    },
  },
};
</script>

<style lang="scss"></style>
