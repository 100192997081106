<template>
  <div v-if="user">
    <sb-loading v-if="loading" />
    <!-- <Breadcrumb>
      <BreadcrumbItem>
        <RouterLink :to="{name: 'ResourceManagementManageres'}">
          Manageres
        </RouterLink>
      </BreadcrumbItem>
      <BreadcrumbItem>{{ user.firstName }} {{ user.lastName }}</BreadcrumbItem>
    </Breadcrumb> -->

    <sb-page-header>
      <template v-slot:left>
        <h1>
          {{ user.firstName }} {{ user.lastName }}
          <small>Schooldistrictbeheerder</small>
        </h1>
      </template>
      <template v-slot:right>
        <dropdown placement="bottom-end">
          <i-button type="primary" ghost>
            Acties
            <icon type="md-more" />
          </i-button>

          <dropdown-menu slot="list" style="width: 220px">
            <router-link
              :to="{
                name:
                  'ResourceManagementSchooldistrictManagersSchooldistrictManagerEdit',
              }"
            >
              <sb-dropdown-button> Gegevens bewerken </sb-dropdown-button>
            </router-link>
            <router-link
              :to="{
                name:
                  'ResourceManagementSchooldistrictManagersSchooldistrictManagerEditPassword',
              }"
            >
              <sb-dropdown-button> Wachtwoord wijzigen </sb-dropdown-button>
            </router-link>
            <sb-dropdown-button style="color: red" @click="confirmArchiveUser">
              Gebruiker verwijderen
            </sb-dropdown-button>
          </dropdown-menu>
        </dropdown>
      </template>
    </sb-page-header>

    <sb-tabbed-page :data="user" />
  </div>
</template>

<script>
import SbLoading from '@/components/SbLoading';

import SbPageHeader from '@/components/SbPageHeader';
import SbTabbedPage from '@/components/SbTabbedPage';

import MutationArchiveUsers from '@/graphql/mutations/ArchiveUsers.gql';

export default {
  components: {
    SbPageHeader,
    SbTabbedPage,
    SbLoading,
  },
  props: {
    schooldistrictManagerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async archiveUser() {
      this.loading = true;
      const { data } = await this.$apollo.mutate({
        mutation: MutationArchiveUsers,
        variables: {
          userIds: [this.user.id],
        },
      });
      setTimeout(() => {
        this.loading = false;
        this.user = null;
        if (data.archiveUser) {
          this.$Modal.info({
            title: 'Gelukt',
            body: 'De gebruiker is succesvol verwijderd',
            onOk: () => {
              this.$router.navigateBack();
            },
          });
        }
      }, 500);
    },
    confirmArchiveUser() {
      this.$Modal.confirm({
        title: 'Let op!',
        content: 'Weet je zeker dat je deze gebruiker wilt verwijderen?',
        closable: true,
        onOk: () => {
          this.archiveUser();
        },
      });
    },
  },
  apollo: {
    user: {
      skip() {
        return !this.schooldistrictManagerId;
      },
      query: false,
      variables() {
        return {
          id: this.schooldistrictManagerId,
        };
      },
      update(data) {
        if (!data.user) {
          this.$Modal.warning({
            title: 'Melding',
            content: 'Deze gebruiker is niet gevonden',
            onOk: () => {
              this.$router.navigateBack();
            },
          });
        }
        return data.user;
      },
    },
  },
};
</script>

<style lang="scss"></style>
