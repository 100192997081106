<template>
  <div>
    <sb-spacer height="40" />

    <div style="display: flex; justify-content: flex-end">
      <i-button
        type="primary"
        @click="
          $router.push({
            name:
              'ResourceManagementSchooldistrictManagersSchooldistrictManagerEdit',
          })
        "
      >
        Gegevens bewerken
      </i-button>
    </div>

    <row :gutter="25" style="max-width: 1000px">
      <i-col :xs="{ span: 24 }" :lg="{ span: 12 }">
        <sb-divider title="Algemeen" />

        <sb-list>
          <sb-list-item label="id:">
            {{ data.id }}
          </sb-list-item>
          <sb-list-item label="Gebruikersnaam:">
            {{ data.username }}
          </sb-list-item>
          <sb-list-item label="Naam:">
            {{ data.firstName }} {{ data.prefix || '' }} {{ data.lastName }}
          </sb-list-item>
          <sb-list-item label="E-mailadres:">
            {{ data.email }}
          </sb-list-item>
          <sb-list-item label="Laatse inlog:">
            {{ data.mostRecentLoginDate | date(true) }}
          </sb-list-item>
        </sb-list>
      </i-col>
      <i-col :xs="{ span: 24 }" :lg="{ span: 12 }">
        <!-- <SbDivider title="Bezoekadres" />

        <SbList>
          <SbListItem label="Straatnaam:">
            {{ data.address.street }}
          </SbListItem>
          <SbListItem label="Huisnummer:">
            {{ data.address.number }}
          </SbListItem>
          <SbListItem label="Postcode:">
            {{ data.address.postalCode }}
          </SbListItem>
          <SbListItem label="Stad:">
            {{ data.address.city }}
          </SbListItem>
          <SbListItem label="Land:">
            {{ data.address.country }}
          </SbListItem>
        </SbList> -->
      </i-col>
    </row>

    <sb-spacer height="100" />
  </div>
</template>

<script>
import SbDivider from '@/components/SbDivider';
import SbList from '@/components/SbList';
import SbListItem from '@/components/SbListItem';

export default {
  components: {
    SbDivider,
    SbList,
    SbListItem,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style></style>
